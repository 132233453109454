import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Icon from "components/icon";
import { CompanyContext } from "context";
import { useContext } from "react";
import * as SC from "./style";

const FlagExplanationCard = () => {
  const { company } = useContext(CompanyContext);

  const displayEventsFlag = () => {
    switch (company.eventsFlag) {
      case "Critical":
        return <Icon name="flag" text="התרעה משמעותית" size="2rem" />;
      case "High":
        return <Icon name="flag" text="התרעה" size="2rem" />;
      default:
        return false;
    }
  };

  return (
    <SC.Card variant="outlined" style={{ height: "fit-content" }}>
      <CardContent>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography
              variant="subtitle1"
              component="div"
              p="0 .3rem"
              style={{ whiteSpace: "pre-line" }}
              gutterBottom
            >
              {company.flagExplanation}
            </Typography>
          </div>
          {displayEventsFlag()}
        </Box>
      </CardContent>
    </SC.Card>
  );
};

export default FlagExplanationCard;
