import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import { AuthContext } from "context";
import { useSelectedLanguage } from "language";
import { useContext, useState } from "react";

import { Link } from "react-router-dom";
import { Company } from "types";
import * as SC from "./style";

const CollapseCard = (props: Company) => {
  const {
    id,
    name,
    name_en,
    esgGrade,
    badActivities,
    events,
    governance,
    social,
    environmental,
    primarySector,
    greeneyeSector,
  } = props;
  const [expanded, setExpanded] = useState(false);
  const { userData } = useContext(AuthContext);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const { selectedLanguage, selectedDictionary } = useSelectedLanguage();

  return (
    <SC.Card variant="outlined">
      <SC.CompanyCard>
        <CardActions>
          <Box
            onClick={handleExpandClick}
            sx={{
              cursor: "pointer",
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: ".5rem 1rem",
            }}
          >
            <Typography variant="body1" color="primary.main">
              {selectedLanguage === "en" ? name_en : name}
            </Typography>
            {expanded ? (
              <ArrowDropUpIcon fontSize="small" />
            ) : (
              <ArrowDropDownIcon fontSize="small" />
            )}
          </Box>
        </CardActions>
      </SC.CompanyCard>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent sx={{ padding: "0 1rem" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Typography variant="subtitle1" fontWeight="fontWeightMedium">
              {(selectedDictionary as any).card?.sector}
            </Typography>
            <Typography pl=".5rem" variant="subtitle1">
              {primarySector?.name || "-"}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Typography variant="subtitle1" fontWeight="fontWeightMedium">
              {(selectedDictionary as any).card?.greeneyeSector}
            </Typography>
            <Typography pl=".5rem" variant="subtitle1">
              {greeneyeSector?.name || "-"}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Typography variant="subtitle1" fontWeight="fontWeightMedium">
              {(selectedDictionary as any)?.esgGrade}
            </Typography>
            <Typography pl=".5rem" variant="subtitle1">
              {userData.letterGrading
                ? esgGrade.letterGrade
                : esgGrade.grade || "-"}
            </Typography>
          </Box>
          {userData.letterGrading ? (
            ""
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Typography variant="subtitle1" fontWeight="fontWeightMedium">
                  {(selectedDictionary as any).card?.socialGrade}
                </Typography>
                <Typography pl=".5rem" variant="subtitle1">
                  {social?.grade || "-"}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Typography variant="subtitle1" fontWeight="fontWeightMedium">
                  {(selectedDictionary as any).card?.gorvernanceGrade}
                </Typography>
                <Typography pl=".5rem" variant="subtitle1">
                  {governance?.grade || "-"}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Typography variant="subtitle1" fontWeight="fontWeightMedium">
                  {(selectedDictionary as any).card?.environmentalGrade}
                </Typography>
                <Typography pl=".5rem" variant="subtitle1">
                  {environmental?.grade || "-"}
                </Typography>
              </Box>
            </>
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Typography variant="subtitle1" fontWeight="fontWeightMedium">
              {(selectedDictionary as any).card?.incidents}
            </Typography>
            <Typography pl=".5rem" variant="subtitle1">
              {events?.length > 0
                ? (selectedDictionary as any).yes
                : (selectedDictionary as any).no}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Typography variant="subtitle1" fontWeight="fontWeightMedium">
              {
                (selectedDictionary as any).card
                  ?.involvementInDisputableActivities
              }
            </Typography>
            <Typography pl=".5rem" variant="subtitle1">
              {badActivities?.length
                ? (selectedDictionary as any).yes
                : (selectedDictionary as any).no}
            </Typography>
          </Box>
          <Box textAlign="center" pt="1.2rem">
            <Link to={`report/${id}`} style={{ textDecoration: "none" }}>
              <Button variant="contained" disableElevation size="small">
                {(selectedDictionary as any)?.companyReport}
              </Button>
            </Link>
          </Box>
        </CardContent>
      </Collapse>
    </SC.Card>
  );
};

export default CollapseCard;
