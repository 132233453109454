import { useMutation } from "@apollo/client";
import SearchIcon from "@mui/icons-material/Search";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ReactComponent as GreeneyeLogo } from "assets/Greeneye-logo.svg";
import { ReactComponent as GreeneyeG } from "assets/greeneyeG.svg";
import { AuthContext, CompanyContext } from "context";
import { LandingPageContext, Mode } from "context/landingPage";
import { UPDATE_CONFIG } from "graphql/mutation";
import { useSelectedLanguage } from "language";
import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useCompaniesFilters, useFundsFilters } from "stores";
import { debounce, isCompaniesScreen } from "utils";
import BasicMenu from "./menu";
import * as SC from "./style";
const pj = require("../../../package.json");

export const Toggle = () => {
  const { userData, setUserData } = useContext(AuthContext);
  const [alignment, setAlignment] = useState(userData.letterGrading);
  const [updateMutaion] = useMutation(UPDATE_CONFIG);
  const { selectedDictionary } = useSelectedLanguage();
  const handleChange = async (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: boolean
  ) => {
    try {
      await updateMutaion({
        variables: {
          id: userData.id,
          data: {
            letterGrading: newAlignment,
          },
        },
      });
      setAlignment(newAlignment);
      setUserData({ ...userData, letterGrading: newAlignment });
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <ToggleButtonGroup
      color="primary"
      value={alignment}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
    >
      <ToggleButton value={true}>
        {(selectedDictionary as any).letter}
      </ToggleButton>
      <ToggleButton value={false}>
        {(selectedDictionary as any).number}
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userData, logout } = useContext(AuthContext);
  const { mode } = useContext(LandingPageContext);
  const { searchTerm, setSearchTerm } = useCompaniesFilters();
  const { searchTermFunds, setSearchTermFunds } = useFundsFilters();
  const username = userData?.username;
  const isShowSearchInput = isCompaniesScreen(location.pathname);
  const matchesMd = useMediaQuery("(min-width:900px)");
  const matchesSm = useMediaQuery("(min-width:700px)");
  const [searchText, setSearchText] = useState("");
  const [timeoutId, setTimeoutId] = useState<any>(0);
  const { selectedDictionary, selectedDirection } = useSelectedLanguage();

  const signout = () => {
    logout();
    navigate("/signin");
  };

  const handleChange = (e: any) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    if (mode === Mode.COMPANIES)
      setTimeoutId(debounce(() => setSearchTerm(searchText), timeoutId));
    else if (mode === Mode.FUNDS) {
      setTimeoutId(debounce(() => setSearchTermFunds(searchText), timeoutId));
    }
  }, [searchText]);

  useEffect(() => {
    setSearchText(searchTerm);
  }, [searchTerm]);
  useEffect(() => {
    setSearchText(searchTermFunds);
  }, [searchTermFunds]);

  const options = {
    FUNDS: {
      placeholder: `${selectedDictionary.fundSearch}`,
    },
    COMPANIES: {
      placeholder: `${selectedDictionary.companySearch}`,
    },
  };
  return (
    <SC.AppBarWrapper>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        p="1rem 3rem"
      >
        <Grid item md={3} sm={1} xs={2} pt=".3rem">
          <Link to="/">
            {matchesMd ? (
              <GreeneyeLogo height="1.6rem" width="8rem" />
            ) : (
              <GreeneyeG height="1.8rem" width="1.8rem" />
            )}
          </Link>
        </Grid>
        <Grid item md={4} xs={6}>
          {isShowSearchInput && (
            <SC.Search>
              <SC.StyledInputBase
                placeholder={options[mode as keyof typeof Mode].placeholder}
                // placeholder={(selectedDictionary as any).companySearch} TODO
                value={searchText}
                onChange={handleChange}
                inputProps={{ "aria-label": "search" }}
              />
              <SC.SearchIconWrapper selectedDirection={selectedDirection}>
                <SearchIcon />
              </SC.SearchIconWrapper>
            </SC.Search>
          )}
        </Grid>
        <Grid
          item
          container
          xs={4}
          sm={5}
          justifyContent="left"
          flexBasis="end"
          alignItems="center"
        >
          {matchesSm && (
            <Grid item sm={6}>
              <Typography
                paddingLeft="2rem"
                color="#FFFFFF"
                variant="h5"
                textAlign="left"
              >
                {username}
              </Typography>
            </Grid>
          )}
          <Grid item sm={3} textAlign="left">
            <BasicMenu />
          </Grid>
          <Grid item sm={3} textAlign="left">
            <Typography>{"v" + pj?.version}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </SC.AppBarWrapper>
  );
};

export const SubHeader = () => {
  const { selectedLanguage, selectedDictionary, selectedDirection } =
    useSelectedLanguage();
  const { company } = useContext(CompanyContext);
  const {
    name,
    name_en,
    ticker,
    primarySector,
    analysisDate,
    analystName,
    engagement,
    isin,
  } = company;

  return (
    <SC.SubHeaderToolbar selectedDirection={selectedDirection}>
      <SC.SubHeaderLogoWrapper>
        <Typography variant="h2" color="white" pr="2rem" pb="1rem">
          {selectedLanguage === "en" ? name_en : name}
        </Typography>
      </SC.SubHeaderLogoWrapper>
      <Grid container alignItems="flex-end" justifyContent="space-between">
        <Grid item md={1} xs={2}>
          <Typography variant="body2" fontWeight="500" color="white">
            {(selectedDictionary as any).card.sector}
          </Typography>
          <Typography variant="subtitle1" gutterBottom color="white">
            {primarySector?.name || "-"}
          </Typography>
        </Grid>
        <Grid item md={1} xs={2}>
          <Typography variant="body2" fontWeight="500" color="white">
            ISIN
          </Typography>
          <Typography variant="subtitle1" gutterBottom color="white">
            {isin || "-"}
          </Typography>
        </Grid>
        <Grid item md={1} xs={2}>
          <Typography variant="body2" fontWeight="500" color="white">
            Ticker
          </Typography>
          <Typography variant="subtitle1" gutterBottom color="white">
            {ticker || "-"}
          </Typography>
        </Grid>
        <Grid item md={2} xs={3}>
          <Typography variant="body2" fontWeight="500" color="white">
            {(selectedDictionary as any).companyResponsiveness}
          </Typography>
          <Typography variant="subtitle1" gutterBottom color="white">
            {engagement || "-"}
          </Typography>
        </Grid>
        <Grid item md={1} xs={0} />
        <Grid item xs={3}>
          <div>
            <Typography
              variant="body2"
              color="white"
              component="span"
              fontWeight="500"
            >
              {(selectedDictionary as any).analystName}
            </Typography>
            <Typography
              variant="body2"
              color="white"
              component="span"
              style={{ margin: "0.5rem" }}
            >
              {analystName || "-"}
            </Typography>
          </div>
          <div>
            <Typography
              variant="body2"
              gutterBottom
              color="white"
              component="span"
              fontWeight="500"
            >
              {(selectedDictionary as any).analysisDate}
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              color="white"
              component="span"
              style={{ margin: "0.5rem" }}
            >
              {analysisDate &&
                (new Date(analysisDate).toLocaleDateString("he") || "-")}
            </Typography>
          </div>
        </Grid>
      </Grid>
    </SC.SubHeaderToolbar>
  );
};

const MyAppBar = () => {
  return (
    <>
      <AppBar position="static" className="greeneye-appbar">
        <Header />
      </AppBar>
    </>
  );
};

export default MyAppBar;
