import client from "apollo";
import { formatCompanyIssues, formatIssues } from "formatters";
import { COMPANIES_WITH_ISSUES, ISSUES } from "graphql/query";
import { CompanyWithIssues, CsvDetailHeader } from "types";
export const isCompaniesScreen = (location: string) => location === "/";

/**
 * Runs a function after a given delay.
 * If the function is called again before the delay is over, the previous timeout is cancelled and the new one is set.
 * @param {Function} callback the function to run
 * @param {number} timeout the delay in milliseconds
 */
export function debounce(
  callback: () => Promise<void> | void,
  timeoutId: number,
  timeout = 500
) {
  if (timeoutId) {
    clearTimeout(timeoutId);
  }
  timeoutId = setTimeout(async () => {
    await callback();
  }, timeout) as unknown as number;
  return timeoutId;
}

export const translateManagement = (management?: number) => {
  switch (management) {
    case 1:
      return 0;
    case 2:
      return 25;
    case 3:
      return 50;
    case 4:
      return 75;
    case 5:
      return 100;
    default:
      return;
  }
};

export const translateRisk = (risk?: number) => {
  switch (risk) {
    case 1:
      return "נמוכה מאד";
    case 2:
      return "נמוכה";
    case 3:
      return "בינונית";
    case 4:
      return "גבוהה";
    case 5:
      return "גבוהה מאד";
    default:
      return;
  }
};

export const getCompaniesWithIssues = async (queryFilters: any) => {
  const allCompaniesWithIssues: CompanyWithIssues[] = [];
  let newCompanies: CompanyWithIssues[] = [];
  let page = 0;
  do {
    const { data } = await client.query({
      query: COMPANIES_WITH_ISSUES,
      variables: {
        filters: { ...queryFilters },
        pagination: {
          page,
          limit: 100,
        },
      },
    });
    newCompanies = formatCompanyIssues(data?.myCompanies?.data);
    allCompaniesWithIssues.push(...newCompanies);
    page++;
  } while (newCompanies?.length === 100);
  return allCompaniesWithIssues;
};

export const setCsvIssueHeaders = async (
  selectedCsvHeaders: any,
  selectedDictionary: any
) => {
  const csvDetailHeaders: CsvDetailHeader[] = selectedCsvHeaders;
  const { data } = await client.query({
    query: ISSUES,
  });
  const issues = formatIssues(data?.issues?.data);
  for (const issue of issues) {
    csvDetailHeaders.push({
      label: issue.label + ` ${(selectedDictionary as any).grade}`,
      key: issue.label + " - ציון",
    });
    csvDetailHeaders.push({
      label: issue.label + ` ${(selectedDictionary as any).exposure}`,
      key: issue.label + " - חשיפה",
    });
  }
  return csvDetailHeaders;
};
