import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import SearchIcon from "@mui/icons-material/Search";
import Checkbox from "@mui/material/Checkbox";
import Collapse from "@mui/material/Collapse";
import FormControlLabel from "@mui/material/FormControlLabel";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { CompaniesContext } from "context";
import { useSelectedLanguage } from "language";
import { Fragment, useContext, useState } from "react";
import { useCompaniesFilters } from "stores";
import { FilterItem, TextValuePair } from "types";
import { debounce } from "utils";
import * as SC from "./style";

interface Props {
  title: string;
  options: TextValuePair<any>[];
  id: string;
  type: string;
}

const CollapseFilter = (props: any) => {
  const { filters, setFilters } = useCompaniesFilters();
  const { setPage } = useContext(CompaniesContext);
  const [storeState] = filters?.filter((s: any) => s.id === props.id);
  const [open, setOpen] = useState(false);
  const [timeoutId, setTimeoutId] = useState<number>(0);
  const handleClick = () => {
    setOpen(!open);
  };
  const { selectedDirection } = useSelectedLanguage();
  const handleToggle = (value: TextValuePair<any>) => () => {
    const currentIndex =
      storeState?.values?.findIndex(
        (i: TextValuePair<any>) => i.value === value.value
      ) || 0;
    const newChecked = [...(storeState?.values || [])];

    if (props?.type === "Radio") {
      newChecked.length = 0;
    }

    if (!newChecked.length || currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    const newState = filters?.map((s: FilterItem) =>
      s.id === props?.id
        ? {
            title: props.title,
            values: newChecked,
            id: s.id,
            options: s.options,
            type: s.type,
          }
        : s
    );
    setFilters(newState);
    const tId = debounce(() => setPage(0), timeoutId);
    setTimeoutId(tId);
  };

  return (
    <Fragment>
      <ListItemButton onClick={handleClick}>
        <SC.ListLine selectedDirection={selectedDirection}>
          <ListItemText secondary={props.title} sx={{ fontWeight: "500" }} />
          {open ? (
            <ArrowDropUpIcon fontSize="small" />
          ) : (
            <ArrowDropDownIcon fontSize="small" />
          )}
        </SC.ListLine>
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {props?.type === "CheckBox" ? (
          <>
            {props?.id === "indexes" ? (
              <SC.Search>
                <SC.StyledInputBase
                  placeholder="מסנן אינדקס"
                  value={props.searchText}
                  onChange={props.handleChange}
                  inputProps={{ "aria-label": "search" }}
                />
                <SC.SearchIconWrapper>
                  <SearchIcon />
                </SC.SearchIconWrapper>
              </SC.Search>
            ) : (
              ""
            )}
            <List
              sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
              style={{
                overflowY: props?.id === "indexes" ? "scroll" : "hidden",
              }}
            >
              {props?.options?.map((item: TextValuePair<any>, i: number) => {
                return (
                  <ListItem key={i} disablePadding>
                    <ListItemButton
                      role={undefined}
                      onClick={handleToggle(item)}
                      dense
                    >
                      <ListItemIcon>
                        <SC.ListLine selectedDirection={selectedDirection}>
                          <Checkbox
                            checked={storeState.values?.indexOf(item) !== -1}
                            tabIndex={-1}
                            disableRipple
                            size="small"
                          />
                          <ListItemText
                            id={String(i)}
                            secondary={`${item.text}`}
                            style={{ fontWeight: "300" }}
                          />
                        </SC.ListLine>
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </>
        ) : (
          <RadioGroup>
            {props?.options?.map((item: TextValuePair<any>, i: number) => {
              return (
                <FormControlLabel
                  key={i}
                  value={item.value}
                  control={<Radio disableRipple />}
                  label={item.text}
                  onClick={handleToggle(item)}
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontSize: "14px",
                      fontWeight: "300",
                      transform: "translateY(12.5px)",
                    },
                    "& .MuiSvgIcon-root": {
                      transform: "scale(0.8)",
                    },
                  }}
                />
              );
            })}
          </RadioGroup>
        )}
      </Collapse>
    </Fragment>
  );
};

export default CollapseFilter;
