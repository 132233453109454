import styled from "styled-components";

export const FirstRowWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 1em;
`

export const SecondRowWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1em;
`

export const CoupleItemRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
`

export const SingleItemRow = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`

export const ReportScreenWrapper = styled.div`
  padding: 2rem 3rem 6rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
  background-color: #EFF2F5;
  width: 100%;
`