export default {
  filters: {
    filters: "Filters",
    clearAll: "Clear All Filters",
    indecies: "Indices",
    ipos: "IPOs",
    experimentalIndex: "Experimental Index",
    bonds: "Bonds",
    telAviv125: "Tel Aviv 125",
    generalShares: "General Shares",
    sectors: "Sectors",
    letter: "ESG Letter Rating",
  },
  sectors: {
    renewableEnergy: "Renewable Energy",
    credit: "Credit",
    insurance: "Insurance",
    commercialBanks: "Commercial Banks",
    fuelDistribution: "Fuel Distribution",
    realEstateInvestment: "Real Estate Investment",
    oilRefining: "Oil Refining",
    oilGasExpProd: "Oil & Gas Exploration & Production",
    agriculture: "Agriculture",
    electricityUtility: "Electricity Utility",
    financialInstitutions: "Financial Institutions",
    generalManufacturors: "General Manufacturors",
    chemicals: "Chemicals",
    semiconductors: "Semiconductors",
    buildingMaterials: "Building Materials",
    electronics: "Electronics",
    foods: "Foods",
    biotechnology: "Biotechnology",
    matchMediaachinery: "Machinery",
    hotelsAndTourism: "Hotels & Tourism",
    realEstate: "Real Estate & Construction",
    utility: "Utility Management & Maintenance",
    supermarkets: "Supermarkets and Food Retail",
    woodAndPaper: "Wood & Paper Products",
    medicalSolution: "Development of Medical Devices & Solutions",
    plastics: "Plastics & Rubber",
    pharmaceutics: "Pharmaceutics",
    retail: "General Retail",
    robotics: "Robotics & 3D",
    wellfareAndHealthcare: "Wellfare & Healthcare Services",
    shipping: "Shipping & Logistics",
    software: "Software",
    airlines: "Airlines ",
    aerospace: "Aerospace & Defense",
    communications: "Communications & Media",
    communicationsInfrastructure: "Communications Infrastructure & Equipment",
  },
  esgGrade: "ESG Grade",
  min: "Minimum",
  max: "Maximum",
  esgComparedToSector: "ESG Rating Compared to Sector",
  equalOrAboveAvg: "Equal or Above Average",
  belowAverage: "Below Average",
  esgGradePercentile: "ESG Grade by Percentile",
  percentileInSector: "Percentile in Sector",
  incidentsAndAlerts: "Incidents and Alerts",
  incidents: "Incidents (no additional alert)",
  alerts: "Alerts",
  redFlagAlerts: "Red Flag Alert",
  noIncidents: "No Incidents",
  sustainableDevelopment: "Goods & Services Promoting Sustainable Development",
  yes: "Yes",
  no: "No",
  criticalEvents: "Critical Events",
  health: "Health",
  foodSecurity: "Food Security",
  safety: "Safety",
  financialInclusion: "Financial Inclusion",
  publicTransport: "Public Transport",
  pollutionReduction: "Pollution Reduction",
  elseducation: "Education",
  sustainableElectricity: "Sustainable Electricity",
  telecommunications: "Telecommunications",
  waterAndSanitation: "Water & Sanitation",
  climateChangeAdaptation: "Climate Change - Adaptation",
  climateChangeMitigation: "Climate Change - Mitigation",
  disputableGoods: "Disputable Goods & Services",
  failossilFuels: "Fossil Fuels",
  gambling: "Gambling",
  pesticides: "Pesticides",
  tobacco: "Tobacco",
  indiscriminateWeapons: "Indiscriminate Weapons",
  chemicalsProhibitedByInternationalConventions:
    "Chemicals Prohibited By International Conventions",
  naturalResourceMining: "Natural Resource Mining",
  animalTesting: "Animal Testing for Medical Purposes",
  securitySystems: "Sales of Security Systems",
  nuclearEnergy: "Nuclear Energy Involvement",
  nuclearArms: "Nuclear Arms Involvement",
  saleManufacturingArmsForCivilians:
    "Sales Manufacturing of Arms for Civilians",
  Pornography: "Pornography",
  card: {
    sector: "Sector",
    greeneyeSector: "Greeneye Sector",
    socialGrade: "Social Grade",
    gorvernanceGrade: "Governance Grade",
    environmentalGrade: "Environmental Grade",
    incidents: "Incidents",
    involvementInDisputableActivities: "Involvement in Disputable Activities",
  },
  companyReport: "Present Company Report",
  exit: "Exit",
  additionalCompanies: "Additional Companies",
  additionalFunds: "Additional Funds",
  loading: "Loading",
  found: "Found",
  companies: "Companies",
  dataSummary: "Data Summary",
  elaborateDataExport: "Elaborate Data Export",
  analysisSummary: "Analysis Summary",
  login: "Enter System",
  email: "Email",
  password: "Password",
  enter: "Enter",
  responsibleInvestmentExpert: "Responsible Investment Expert",
  header: "Company Analysis System",
  header2: "ESG",
  companyResponsiveness: "Company Responsiveness",
  analystName: "Analyst Name",
  analysisDate: "Analysis Date",
  companySearch: "Company Search",
  fundSearch: "Fund Search",
  downloading: "Downloading...",
  noResult: "No companies found, try another filter",
  noResultFunds: "No funds found",
  grade: "Grade",
  exposure: "Exposure",
  management: "Management",
  risk: "Risk",
  companiesComparisonCell1: "Incidents",
  companiesComparisonCell2: "Environment",
  companiesComparisonCell3: "Social",
  companiesComparisonCell4: "Corporate Governance",
  companiesComparisonCell5: "Company Comparison in Sector",
  companyDetailReportCell1: "Issue",
  companyDetailReportCell2: "Exposure",
  companyDetailReportCell3: "Management",
  activityCell1: "Nothing To Display",
  companyEvaluationCell1: "Domain",
  companyEvaluationCell2: "Domain Materiality to Sector",
  companyEvaluationCell3: "Assessment includes a field",
  activeCountriesCell1: "Main Countries of Activity",
  activeCountriesCell2: "Developed",
  activeCountriesCell3: "Developing",
  disclaimerCell1: "Disclaimer",
  esgGradeCell1: "Exhaustive ESG Assessment",
  esgGradeCell2: "Company Grade",
  esgGradeCell3: "Sector Average",
  esgGradeCell4: "Sector Grade Range",
  companyIssueCell1: "Exposure",
  companyIssueCell2: "Management",
  incidentsReportCell1: "Issue",
  incidentsReportCell2: "Severity",
  incidentsReportCell3: "Mitigation",
  reportIndexCell1: "Company Activity",
  reportIndexCell2: "Environmental Domain",
  reportIndexCell3: "Social Domain",
  reportIndexCell4: "Governance Domain",
  reportIndexCell5: "Analyst Summary",
  reportIndexCell6: "Incidents",
  reportIndexCell7: "Goods & Services Promoting Sustainable Development",
  reportIndexCell8: "Disputable Goods & Services",
  reportIndexCell9: "Assessment Summary",
  reportIndexCell10: "Elaborate Assessment",
  reportIndexCell11: "Environmental Domain",
  reportIndexCell12: "Social Domain",
  reportIndexCell13: "Governance Domain",
  reportIndexCell14: "Print",
  reportIndexCell15: "Environmental Domain",
  reportIndexCell16: "Social Domain",
  reportIndexCell17: "Governance Domain",
  reportIndexCell18: "Environment",
  reportIndexCell19: "Social",
  reportIndexCell20: "Corporate Governance",
  progressCell1: "Sector Minimum Grade",
  progressCell2: "Sector Average",
  progressCell3: "Sector Maximum Grade",
  formattersCell1: "Environment",
  formattersCell2: "Social",
  formattersCell3: "Corporate Governance",
  sources: "Sources",
  equalOrAboveAverage: "Equal or Above Average",
  belowAverage: "Below Average",
  options: "Options",
  letter: "Letters",
  number: "Numbers",
  funds: {
    funds: "Funds",
    companies: "Companies",
    fundGrade: "Fund Grade",
    percentAssessed: "Fund Percent Assessed by Greeneye",
    fundDetails: "Fund details",
    benchmarkGrade: "Benchmark Grade",
    date: "Date",
    weightedGrade: "Weighted Grade",
    back: "Back",
    ticker: "Ticker",
    company: "Company Name",
    isRedFlag: "Flag",
    weight: "Weight (%)",
    grade: "Greeneye grade",
    normalizedWeight: "Normalized weight",
    weightedGrade: "Weighted grade",
    goodProducts: "Good Products",
    controversialActivities: "Controversial Activities",
    redFlags: "Red Flags",
    benchmark: "Benchmark",
    assetId: "ID/ISIN",
    fundDate: "Latest Update",
    fundsDisclaimer: "According to normalized weights",
    fundNumber: "Fund Number",
  },
};
