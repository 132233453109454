import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { matchValuesWithText } from "formatters";
import { useSelectedLanguage } from "language";
import { Fragment, useEffect, useState } from "react";
import { usePrintStore } from "stores";
import { Incident, nameLabelPair } from "types";

const CompanyIncident = (props: Incident) => {
  const [expanded, setExpanded] = useState(false);
  const { printing } = usePrintStore();
  const { selectedLanguage } = useSelectedLanguage();
  const collapseRow = () => setExpanded(!expanded);

  useEffect(() => {
    setExpanded(!!printing);
  }, [printing]);

  const {
    name,
    mitigationDescription,
    mitigation,
    severity,
    incidentCheckboxes,
    date,
    description,
  } = props;

  return (
    <Fragment>
      <div className="page-break"></div>
      <Grid
        container
        onClick={collapseRow}
        sx={{
          cursor: "pointer",
          justifyContent: "start",
          alignItems: "center",
          padding: "1rem 1rem",
        }}
        className="page-break"
      >
        <Grid
          item
          xs={4}
          sx={{
            pl: "1rem",
          }}
        >
          <Typography variant="body2">{name}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Box width="min-content">
            {matchValuesWithText(severity, "severity", selectedLanguage)}
          </Box>
        </Grid>
        <Grid item xs={3}>
          {matchValuesWithText(mitigation, "mitigation", selectedLanguage)}
        </Grid>
        <Grid item xs={1}>
          <Box sx={{ width: "100%", textAlign: "left" }}>
            {expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </Box>
        </Grid>
      </Grid>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Divider variant="middle" />
        <Grid
          container
          paddingBottom="1rem"
          wrap={printing ? "nowrap" : "wrap"}
        >
          <Grid item lg={2} md={5} sm={6} xs={printing ? 3 : 12} pb="1rem">
            <Box
              sx={{
                padding: "1rem",
                margin: "1rem",
                borderRadius: "10px",
              }}
              mt={{ xs: "2rem", lg: "3.5rem" }}
            >
              {date && (
                <Typography variant="body2">
                  {new Date(date).toLocaleDateString().replaceAll("/", ".")}
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item lg={6} xs={printing ? 4 : 12}>
            {description && (
              <Box
                sx={{
                  background: "#EFF2F5",
                  padding: "1rem",
                  margin: "1rem",
                  borderRadius: "10px",
                  wordWrap: "break-word",
                }}
                mt={{ xs: "2rem", lg: "3.5rem" }}
              >
                <Typography
                  variant="subtitle1"
                  component="div"
                  p="0 .3rem"
                  style={{ whiteSpace: "pre-line" }}
                  gutterBottom
                >
                  {description}
                </Typography>
              </Box>
            )}
            {mitigationDescription && (
              <Box
                sx={{
                  background: "#EFF2F5",
                  padding: "1rem",
                  margin: ".25rem 1rem 1rem 1rem",
                  borderRadius: "10px",
                  wordWrap: "break-word",
                }}
                mt={{ xs: "2rem", lg: ".25rem" }}
              >
                <Typography
                  variant="subtitle1"
                  component="div"
                  p="0 .3rem"
                  style={{ whiteSpace: "pre-line" }}
                  gutterBottom
                >
                  {mitigationDescription}
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid
            item
            lg={4}
            md={7}
            sm={6}
            xs={printing ? 5 : 12}
            padding="3.3rem 0 1rem"
          >
            {incidentCheckboxes?.map(
              (checkboxItem: nameLabelPair, i: number) => {
                return (
                  <Grid key={i} sx={{ padding: ".3rem", margin: "0" }}>
                    <Typography variant="body2">
                      {checkboxItem.label}
                    </Typography>
                  </Grid>
                );
              }
            )}
          </Grid>
        </Grid>
      </Collapse>
    </Fragment>
  );
};

export default CompanyIncident;
